import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Block,
  FormSelect,
  FormTextField
} from '../../../components/Component';
import FormAutoComplete from '../../../components/form/FormAutoComplete';
import { useForm } from 'react-hook-form';
import FormPriceInput from '../../../components/form/FormPriceInput';
import FormMultiImageUpload from '../../../components/form/FormMultiImageUpload';
import FormMultiSelect from '../../../components/form/FormMultiSelect';
import FormNumberInput from '../../../components/form/FormNumberInput';
import { axios as ax } from '../../../utils/axios';
import { Box, Button } from '@mui/material';
import {
  addItem,
  resetItemCreateState
} from '../../../redux/features/itemsSlice/itemsSlice';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';
import { verticalId } from '../../../utils/configEnv';
import axios from 'axios';

// Create Axios instance
const api = axios.create({
  baseURL: 'https://vpic.nhtsa.dot.gov/api/vehicles',
  headers: {
    // You can add any necessary headers here if needed
    'Content-Type': 'application/json'
  }
});

const AddCarFormFields = () => {
  const methods = useForm();
  const { handleSubmit, control, watch, setValue: setFormValue } = methods;
  const [value, setValue] = useState({});
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [isLoadingVin, setIsLoadingVin] = useState(false);

  const dispatch = useDispatch();
  const { createStatus } = useSelector((state) => state.items);

  // Watch for VIN changes
  const vin = watch('vin');

  // Function to fetch VIN details
  const fetchVinDetails = async (vinNumber) => {
    try {
      setIsLoadingVin(true);

      // Use the custom Axios instance for the request
      const response = await api.get(
        `/DecodeVinValues/${vinNumber}?format=json`,
        {
          // You can set timeout, proxy options, etc., if needed
        }
      );

      const data = response.data.Results[0];

      // Map NHTSA API response to form fields
      if (data) {
        setFormValue('make', data.Make);
        setFormValue('model', data.Model);
        setFormValue('year', parseInt(data.ModelYear));
        setFormValue('engine', `${data.DisplacementL}L`);
        setFormValue('cylinder', data.EngineCylinders);
        setFormValue('fuel', data.FuelTypePrimary);
        setFormValue('type', mapBodyClassToType(data.BodyClass));
        setFormValue('transmission', data.TransmissionStyle);
        setFormValue('trim', data.Series);
        setFormValue('drive_train', mapDriveTypeToType(data.DriveType));

        setValue((prev) => ({
          ...prev,
          make: data.Make
        }));
      }
    } catch (error) {
      toast.error('Error fetching VIN details');
      console.error('VIN lookup error:', error);
    } finally {
      setIsLoadingVin(false);
    }
  };

  // Helper function to map NHTSA body class to your form's type options
  const mapBodyClassToType = (bodyClass) => {
    const typeMap = {
      Coupe: 'Coupe',
      Sedan: 'Sedan',
      'Sport Utility Vehicle': 'SUV',
      Hatchback: 'Hatchback'

      // Add more mappings as needed
    };
    return typeMap[bodyClass] || '';
  };
  // Helper function to map NHTSA body class to your form's type options
  const mapDriveTypeToType = (bodyClass) => {
    const typeMap = {
      'RWD/Rear-Wheel Drive': 'RWD',
      'FWD/Front-Wheel Drive': 'FWD',
      'AWD/All-Wheel Drive': 'AWD',
      '4WD/4-Wheel Drive/4x4': '4WD',
      '2WD/4WD': '2WD'

      // Add more mappings as needed
    };
    return typeMap[bodyClass] || '';
  };

  // Debounce VIN lookup
  useEffect(() => {
    const timer = setTimeout(() => {
      if (vin && vin.length === 17) {
        fetchVinDetails(vin);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [vin]);

  // Original form logic
  useEffect(() => {
    const subscription = watch((lvalue, { name, type }) => setValue(lvalue));
    return () => subscription.unsubscribe();
  }, [watch]);

  const fetchAutocompleteOptions = async (data) => {
    try {
      let url = `/device/api/v1/autocomple/list`;
      return ax.post(url, data).then((response) => {
        return response.data?.['data']?.['data'];
      });
    } catch (e) {
      console.error('Error fetching autocomplete options:', e);
      return [];
    }
  };

  const loadOptions = async (inputValue, key, reference) => {
    let query = {
      query: {
        key: key,
        search: inputValue ? inputValue : undefined,
        referenceValue: reference,
        referenceKey: reference ? 'CAR_MAKE' : undefined,
        hasReference: reference ? true : false
      },
      options: { pagination: false }
    };
    const options = await fetchAutocompleteOptions(query);
    return options.map((e) => ({ label: e.value, value: e.value }));
  };

  useMemo(() => {
    const fetchdata = async () => {
      let data = await loadOptions('', 'CAR_MAKE');
      setMakes(data);
    };
    fetchdata();
  }, []);

  useMemo(() => {
    const fetchdata = async () => {
      let data = await loadOptions('', 'CAR_MODEL', value['make']);
      setModels(data);
    };
    fetchdata();
  }, [value['make']]);

  useEffect(() => {
    if (createStatus === 'error') {
      toast.error('Failed to add car');
      dispatch(resetItemCreateState());
    }
  }, [createStatus]);

  const handelCreateCar = async (data) => {
    const payload = {
      vertical_id: verticalId,
      ...data
    };
    dispatch(addItem(payload));
  };

  return (
    <form onSubmit={handleSubmit(handelCreateCar)}>
      <Box position="relative">
        <FormTextField
          name="vin"
          label="VIN"
          capitalise={true}
          control={control}
          helperText="Enter 17-character VIN to auto-fill details"
        />
        {isLoadingVin && (
          <Box position="absolute" right={8} top={8}>
            <Spinner size="sm" />
          </Box>
        )}
      </Box>

      <FormAutoComplete
        name="make"
        label="Make"
        control={control}
        loadOptions={(v) => loadOptions(v, 'CAR_MAKE')}
        options={makes}
      />
      <FormAutoComplete
        name="model"
        label="Model"
        control={control}
        loadOptions={(v) => loadOptions(v, 'CAR_MODEL', value['make'])}
        options={models}
      />
      <FormTextField name="trim" label="Trim" control={control} />
      <FormSelect
        name="engine"
        label="Engine"
        control={control}
        options={[
          '1.0L',
          '1.2L',
          '1.3L',
          '1.4L',
          '1.5L',
          '1.6L',
          '1.7L',
          '1.8L',
          '1.9L',
          '2.0L',
          '2.1L',
          '2.2L',
          '2.3L',
          '2.4L',
          '2.5L',
          '2.6L',
          '2.7L',
          '2.8L',
          '2.9L',
          '3.0L',
          '3.1L',
          '3.2L',
          '3.3L',
          '3.4L',
          '3.5L',
          '3.6L',
          '3.7L',
          '3.8L',
          '3.9L',
          '4.0L',
          '4.13L',
          '4.1L',
          '4.2L',
          '4.3L',
          '4.4L',
          '4.5L',
          '4.6L',
          '4.7L',
          '4.8L',
          '5.0L',
          '5.19L',
          '5.2L',
          '5.3L',
          '5.4L',
          '5.5L',
          '5.6L',
          '5.7L',
          '5.8L',
          '5.9L',
          '6.0L',
          '6.1L',
          '6.2L',
          '6.3L',
          '6.4L',
          '6.5L',
          '6.6L',
          '6.7L',
          '6.8L',
          '7.0L',
          '7.2L',
          '7.3L',
          '7.4L',
          '7.5L',
          '7.8L',
          '8.0L',
          '8.1L',
          '8.3L',
          '8.4L'
        ]}
      />
      <FormSelect
        name="cylinder"
        label="Cylinder"
        control={control}
        options={['1', '2', '3', '4', '5', '6', '8', '12']}
      />
      <FormSelect
        name="year"
        label="Year"
        control={control}
        options={generateVehicleYears()}
      />
      <FormSelect
        name="fuel"
        label="Fuel"
        control={control}
        options={['Gasoline', 'Diesel', 'CNG', 'Electric']}
      />
      <FormSelect
        name="transmission"
        label="Transmission Type"
        control={control}
        options={['Manual', 'Automatic', 'Semi-Automatic']}
      />
      <FormSelect
        name="specs"
        label="Specs"
        control={control}
        options={[
          'GCC',
          'American',
          'Canadian',
          'EU',
          'Japan',
          'Korean',
          'Chinese',
          'Other'
        ]}
      />
      <FormSelect
        name="type"
        label="Type"
        control={control}
        options={[
          'SUV',
          'Coupe',
          'Sedan',
          'Cross Over',
          'Hard Top Convertible',
          'Soft Top Convertible',
          'Pick Up Truck',
          'Hatchback',
          'Sports Car'
        ]}
      />
      <FormSelect
        name="drive_train"
        label="Drive Train"
        control={control}
        options={['4WD', 'AWD', '2WD', 'RWD']}
      />
      <FormNumberInput
        name="mileage"
        label="Mileage"
        unit={'KM'}
        control={control}
        options={[]}
      />
      <FormMultiSelect
        name="tag"
        label="Tags"
        control={control}
        options={['CLEAN_TITLE', 'RUN_&_DRIVE']}
      />
      <FormPriceInput
        name="auction_starting_price"
        label="Auction Starting Price"
        control={control}
      />
      {/* <FormPriceInput
        name="direct_sale_price"
        label="Direct Sale Price"
        control={control}
      /> */}
      <FormMultiImageUpload name="images" label="Images" control={control} maxFilesLength={50}/>

      <Box paddingTop={2} justifyContent="flex-end" display="flex">
        <Button type="submit" variant="contained">
          {createStatus === 'loading' ? <Spinner /> : 'Create'}
        </Button>
      </Box>
    </form>
  );
};

function generateVehicleYears(startYear = 1980) {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = currentYear; year >= startYear; year--) {
    years.push(year);
  }
  return years;
}

export default AddCarFormFields;
