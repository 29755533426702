import RevenueChart from "./RevenueChart";

const DealerRevenueChart = ({ data, title }) => {
    return (
      <RevenueChart
        data={data}
        title={title}
        series={[
          {
            name: 'Credits',
            dataKey: 'totalCredit',
            color: '#4caf50'
          },
          {
            name: 'Debits',
            dataKey: 'totalDebit',
            color: '#f44336'
          }
        ]}
      />
    );
  };

  export default DealerRevenueChart;