import React, { useState } from 'react';
import Content from '../layout/content/Content';
import DealerDashboard from './dashboard/Dashboard';
import { useSelector } from 'react-redux';

const Homepage = () => {

  
  return (
    <Content>
      <DealerDashboard />
    </Content>
  );
};
export default Homepage;
