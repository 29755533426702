export const menu = [
  {
    heading: 'Ravo'
  },
  {
    icon: 'dashboard-fill',
    text: 'Dashboard',
    link: '/dashboard'
  },

  {
    heading: 'Management'
  },

  {
    icon: 'list-fill',
    text: 'Inventory',
    subMenu: [
      {
        text: 'Inventory',
        link: '/items'
      },
      {
        text: 'Add Item',
        link: '/add-item'
      },
      {
        text: 'Bulk Add Item',
        link: '/bulk-add-item'
      },
      {
        text: 'CSV Genertor',
        link: '/csv-generator'
      }
    ]
  },
  {
    icon: 'scan-fill',
    text: 'Auctions',
    subMenu: [

      {
        text: 'List My Auctions',
        link: '/my-auctions'
      },
    ]
  },
  {
    icon: 'scan-fill',
    text: 'Live Group Auctions',
    subMenu: [

      {
        text: 'List Live Group Auctions',
        link: '/auction-groups'
      },
    ]
  },
  // {
  //   icon: 'scan-fill',
  //   text: 'Buy Now',
  //   subMenu: [

  //     {
  //       text: 'List Buy Now',
  //       link: '/direct-sales'
  //     },
  //   ]
  // },
  {
    icon: 'coin-alt-fill',
    text: 'Orders',
    subMenu: [
      {
        text: 'Orders',
        link: '/orders'
      }
    ]
  },

  {
    icon: 'coin-alt-fill',
    text: 'Payments',
    subMenu: [
      {
        text: 'Transactions',
        link: '/payments'
      }
    ]
  },

  {
    icon: 'setting-fill',
    text: 'Settings',
    link:'/settings'
  },




  {
    icon: 'signout',
    text: 'Logout',
    logout: true
  }
];
