// components/dashboard/DealerDashboard.jsx
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  Container, 
  Grid2, 
  CircularProgress,
  TextField 
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  AccountBalance,
  Gavel,
  Storefront,
  TrendingUp,
  LocalOffer,
  Check
} from '@mui/icons-material';
import {
  fetchDealerDashboardAnalytics,
  fetchDealerRangeAnalytics
} from '../../redux/features/dealerAnalyticsSlice/dealerAnalyticsSlice';

import DealerRevenueChart from './components/DealerRevenueChart';
import DealerAuctionsChart from './components/DealerAuctionsChart';
import MetricsCard from './components/MetricsCard';

const DealerDashboard = () => {
  const dealerId =  useSelector((state) => state.auth.loggedInUser?.id);
  const dispatch = useDispatch();
  const { dashboardData, rangeData, dashboardLoading,rangeLoading } = useSelector(state => state.dealerAnalytics);
  const [dateRange, setDateRange] = useState([
    new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
    new Date()
  ]);


  // First useEffect
useEffect(() => {
 dispatch(fetchDealerDashboardAnalytics(dealerId));
}, [dispatch, dealerId]);

// Second useEffect
useEffect(() => {
  if (dateRange[0] && dateRange[1]) {
    dispatch(fetchDealerRangeAnalytics({
      dealerId,
      startDate: dateRange[0].toISOString(),
      endDate: dateRange[1].toISOString()
    }));
  }
}, [dispatch, dealerId, ]);

  if ( !dashboardData) {
    return (
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '80vh' 
      }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      {/* Date Range Selector */}
      <Grid2 container spacing={2} sx={{ mb: 4 }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid2 item xs={12} sm={6} md={3}>
          <DatePicker
            label="Start Date"
            value={dateRange[0]}
            onChange={(newValue) => setDateRange([newValue, dateRange[1]])}
            renderInput={(params) => <TextField {...params} fullWidth />}
            inputFormat="dd/MM/yyyy"
          />
        </Grid2>
        <Grid2 item xs={12} sm={6} md={3}>
          <DatePicker
            label="End Date"
            value={dateRange[1]}
            onChange={(newValue) => setDateRange([dateRange[0], newValue])}
            renderInput={(params) => <TextField {...params} fullWidth />}
            inputFormat="dd/MM/yyyy"
          />
        </Grid2>
        </LocalizationProvider>
      </Grid2>

      {/* Balance Metrics */}
      <Grid2 container spacing={3} sx={{ mb: 4 }}>
        <Grid2 item size={4}>
          <MetricsCard
            title="Current Balance"
            value={new Intl.NumberFormat('en-US', { 
              style: 'currency', 
              currency: 'AED' 
            }).format(dashboardData?.walletBalance || 0)}
            icon={<AccountBalance />}
            color="#2196f3"
          />
        </Grid2>
        <Grid2 item size={4}>
          <MetricsCard
            title="Pending Balance"
            value={new Intl.NumberFormat('en-US', { 
              style: 'currency', 
              currency: 'AED' 
            }).format(dashboardData?.pendingBalance || 0)}
            icon={<TrendingUp />}
            color="#ff9800"
          />
        </Grid2>
        <Grid2 item size={4}>
          <MetricsCard
            title="Total Transferred"
            value={new Intl.NumberFormat('en-US', { 
              style: 'currency', 
              currency: 'AED' 
            }).format(dashboardData?.totalTransferred || 0)}
            icon={<AccountBalance />}
            color="#4caf50"
          />
        </Grid2>
      </Grid2>

      {/* Auction Metrics */}
      <Grid2 container spacing={3} sx={{ mb: 4 }}>
        <Grid2 item size={3}>
          <MetricsCard
            title="Total Auctions"
            value={dashboardData?.auctionMetrics?.totalAuctions || 0}
            icon={<Gavel />}
            color="#9c27b0"
          />
        </Grid2>
        <Grid2 item size={3}>
          <MetricsCard
            title="Sold Auctions"
            value={dashboardData?.auctionMetrics?.soldAuctions || 0}
            icon={<Check />}
            color="#4caf50"
          />
        </Grid2>
        <Grid2 item size={3}>
          <MetricsCard
            title="Active Auctions"
            value={dashboardData?.auctionMetrics?.activeAuctions || 0}
            icon={<LocalOffer />}
            color="#ff9800"
          />
        </Grid2>
        <Grid2 item size={3}>
          <MetricsCard
            title="Success Rate"
            value={`${(dashboardData?.auctionMetrics?.successRate || 0).toFixed(1)}%`}
            icon={<Storefront />}
            color="#2196f3"
          />
        </Grid2>
      </Grid2>

      {/* Charts */}
      <Grid2 container spacing={3}>
        <Grid2 item size={6}>
          <DealerRevenueChart 
            data={rangeData?.transferMetrics || []} 
            title="Monthly Transfer Trends"
          />
        </Grid2>
        <Grid2 item size={6}>
          <DealerAuctionsChart 
            data={rangeData?.auctionMetrics || []} 
            title="Auction Performance"
          />
        </Grid2>
      </Grid2>
    </Container>
  );
};


export default DealerDashboard;