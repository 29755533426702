import React, { useState } from 'react';
import { Box, Card, CardContent, Typography, TextField, Button, Table, 
  TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Download, X, Loader } from 'lucide-react';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';
import Content from '../../layout/content/Content';

const VINGenerator = () => {
  const [vinList, setVinList] = useState('');
  const [generatedData, setGeneratedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Map NHTSA body class to form type options
  const mapBodyClassToType = (bodyClass) => {
    const typeMap = {
      Coupe: 'Coupe',
      Sedan: 'Sedan',
      'Sport Utility Vehicle': 'SUV',
      Hatchback: 'Hatchback',
      Pickup: 'Pick Up Truck',
      Convertible: 'Soft Top Convertible'
    };
    return typeMap[bodyClass] || '';
  };

  // Map NHTSA drive type to form drive train options
  const mapDriveTypeToType = (driveType) => {
    const typeMap = {
      'RWD/Rear-Wheel Drive': 'RWD',
      'FWD/Front-Wheel Drive': '2WD',
      'AWD/All-Wheel Drive': 'AWD',
      '4WD/4-Wheel Drive/4x4': '4WD',
      '2WD/4WD': '2WD'
    };
    return typeMap[driveType] || '';
  };

  // Fetch VIN details from NHTSA API
  const fetchVinDetails = async (vin) => {
    try {
      const response = await fetch(
        `https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVinValues/${vin}?format=json`
      );
      const data = await response.json();
      const result = data.Results[0];
      console.log(result)
      return {
        make: result.Make || '',
        model: result.Model || '',
        year: result.ModelYear || '',
        engine: result.DisplacementL ? `${result.DisplacementL}L` : '',
        cylinder: result.EngineCylinders || '',
        fuel: result.FuelTypePrimary || '',
        type: mapBodyClassToType(result.BodyClass) || '',
        transmission: result.TransmissionStyle || '',
        trim:result.Series,
        drive_train: mapDriveTypeToType(result.DriveType) || '',
        vin: vin,
        mileage: '',
        specs: '',
        tag: '',
        auction_starting_price: '',
        images: ''
      };
    } catch (error) {
      console.error('Error fetching VIN details:', error);
      toast.error(`Error fetching details for VIN: ${vin}`);
      return {
        make: '', model: '', year: '', engine: '', cylinder: '',
        fuel: '', type: '', transmission: '', drive_train: '',
        vin: vin, mileage: '', specs: '', 
        tag: '', auction_starting_price: '', 
        images: ''
      };
    }
  };

  const handleVinInput = (e) => {
    setVinList(e.target.value);
  };

  const validateVINs = (vins) => {
    const vinPattern = /^[A-HJ-NPR-Z0-9]{17}$/;
    return vins.filter(vin => !vinPattern.test(vin));
  };

  const generateCSV = async () => {
    // Split and clean VIN input
    const vins = vinList.split('\n')
      .map(vin => vin.trim())
      .filter(vin => vin.length > 0);

    // Validate VINs
    const invalidVins = validateVINs(vins);
    if (invalidVins.length > 0) {
      toast.error(`Invalid VINs found: ${invalidVins.join(', ')}`);
      return;
    }

    setIsLoading(true);
    try {
      // Fetch details for all VINs
      const vinDetailsPromises = vins.map(vin => fetchVinDetails(vin));
      const vinDetails = await Promise.all(vinDetailsPromises);
      setGeneratedData(vinDetails);
      toast.success('Vehicle details fetched successfully');
    } catch (error) {
      toast.error('Error fetching vehicle details');
    } finally {
      setIsLoading(false);
    }
  };

  const downloadCSV = () => {
    const headers = [
      'make', 'model', 'year', 'mileage', 'vin', 'cylinder', 'engine','trim',
      'fuel', 'transmission', 'specs',  'type', 'drive_train',
      'tag', 'auction_starting_price', 'images'
    ];

    const csvContent = [
      headers.join(','),
      ...generatedData.map(row => 
        headers.map(header => row[header] || '').join(',')
      )
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'vehicles_template.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    toast.success('CSV downloaded successfully');
  };

  const clearAll = () => {
    setVinList('');
    setGeneratedData([]);
    toast.info('Form cleared');
  };

  return (
    <Content>
      <Card>
        <CardContent>
          <Typography variant="h5" component="h2" gutterBottom>
            VIN CSV Generator
          </Typography>

          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Enter VIN Numbers (one per line):
            </Typography>
            <TextField
              multiline
              rows={6}
              fullWidth
              value={vinList}
              onChange={handleVinInput}
              placeholder="WBAAA1305H8251545&#10;WBAAA1305H8251546&#10;WBAAA1305H8251547"
              variant="outlined"
            />
          </Box>

          <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={generateCSV}
              disabled={!vinList.trim() || isLoading}
              startIcon={isLoading ? <Spinner size="sm" /> : null}
            >
              {isLoading ? 'Fetching Details...' : 'Generate Template'}
            </Button>
            
            {generatedData.length > 0 && (
              <>
                <Button
                  variant="contained"
                  color="success"
                  onClick={downloadCSV}
                  startIcon={<Download size={20} />}
                >
                  Download CSV
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={clearAll}
                  startIcon={<X size={20} />}
                >
                  Clear All
                </Button>
              </>
            )}
          </Box>

          {generatedData.length > 0 && (
            <Box sx={{ mt: 4 }}>
              <Typography variant="h6" gutterBottom>
                Preview
              </Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>VIN</TableCell>
                      <TableCell>Make</TableCell>
                      <TableCell>Model</TableCell>
                      <TableCell>Year</TableCell>
                      <TableCell>Engine</TableCell>
                      <TableCell>Cylinders</TableCell>
                      <TableCell>Trim</TableCell>
                      <TableCell>fuel</TableCell>
                      <TableCell>Transmission</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {generatedData.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.vin}</TableCell>
                        <TableCell>{row.make}</TableCell>
                        <TableCell>{row.model}</TableCell>
                        <TableCell>{row.year}</TableCell>
                        <TableCell>{row.engine}</TableCell>
                        <TableCell>{row.cylinder}</TableCell>
                      <TableCell>{row.trim}</TableCell>
                      <TableCell>{row.fuel}</TableCell>
                      <TableCell>{row.transmission}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography variant="caption" sx={{ mt: 1, display: 'block' }}>
                Showing key fields. Download CSV to see all fields.
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    </Content>
  );
};

export default VINGenerator;