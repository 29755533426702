// dealerAnalyticsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axios } from '../../../utils/axios';

export const fetchDealerDashboardAnalytics = createAsyncThunk(
  'dealerAnalytics/fetchDashboard',
  async (dealerId) => {
    const response = await axios.get(`/device/api/v1/analytics/dashboard/${dealerId}`);
    const data =  response.data;
    return data?.data;
  }
);

export const fetchDealerRangeAnalytics = createAsyncThunk(
  'dealerAnalytics/fetchRange',
  async ({ dealerId, startDate, endDate }) => {
    const response = await axios.get(
      `/device/api/v1/analytics/range/${dealerId}?startDate=${startDate}&endDate=${endDate}`
    );
    const data =  response.data;
    return data?.data;
  }
);

const dealerAnalyticsSlice = createSlice({
  name: 'dealerAnalytics',
  initialState: {
    dashboardData: null,
    rangeData: null,
    dashboardLoading: false,  // Separate loading states
    rangeLoading: false,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDealerDashboardAnalytics.pending, (state) => {
        state.dashboardLoading = true;
      })
      .addCase(fetchDealerDashboardAnalytics.fulfilled, (state, action) => {
        state.dashboardLoading = false;
        state.dashboardData = action.payload;
        state.error = null;
      })
      .addCase(fetchDealerDashboardAnalytics.rejected, (state, action) => {
        state.dashboardLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchDealerRangeAnalytics.pending, (state) => {
        state.rangeLoading = true;
      })
      .addCase(fetchDealerRangeAnalytics.fulfilled, (state, action) => {
        state.rangeLoading = false;
        state.rangeData = action.payload;
        state.error = null;
      })
      .addCase(fetchDealerRangeAnalytics.rejected, (state, action) => {
        state.rangeLoading = false;
        state.error = action.error.message;
      });
  }
});

export default dealerAnalyticsSlice.reducer;