import RevenueChart from "./RevenueChart";

const DealerAuctionsChart = ({ data, title }) => {
    return (
      <RevenueChart
        data={data}
        title={title}
        series={[
          {
            name: 'Total Auctions',
            dataKey: 'totalAuctions',
            color: '#2196f3'
          },
          {
            name: 'Sold Auctions',
            dataKey: 'soldAuctions',
            color: '#9c27b0'
          }
        ]}
      />
    );
  };

  export default DealerAuctionsChart;
  